var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":this.$overlayVariant,"spinner-type":this.$overlayType,"rounded":this.$overlayRounded}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-2"})]),_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("สาขา: "),_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitform && _vm.$v.userBranchId.$error,
                            },attrs:{"label":"nameTh","type":"search","options":_vm.optionBranch,"show-labels":false,"open-direction":"bottom","placeholder":""},on:{"input":_vm.getStock},model:{value:(_vm.userBranchId),callback:function ($$v) {_vm.userBranchId=$$v},expression:"userBranchId"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.userBranchId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.userBranchId.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("คลัง: "),_c('multiselect',{class:{
                              'is-invalid': _vm.submitform && _vm.$v.stockId.$error,
                            },attrs:{"label":"nameTh","type":"search","options":_vm.rowsStock,"show-labels":false,"open-direction":"bottom","placeholder":""},model:{value:(_vm.stockId),callback:function ($$v) {_vm.stockId=$$v},expression:"stockId"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.stockId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.stockId.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("วันที่ปรับปรุงคลังสินค้า: "),_c('date-picker',{class:{
                              'is-invalid': _vm.submitform && _vm.$v.auditDate.$error,
                            },attrs:{"format":"YYYY-MM-DD","value-type":"format"},model:{value:(_vm.auditDate),callback:function ($$v) {_vm.auditDate=$$v},expression:"auditDate"}}),(_vm.submitform && _vm.$v.auditDate.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.auditDate.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" หมายเหตุ: "),_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"","rows":"3","max-rows":"6"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 text-end"},[(!this.$route.params.poId)?_c('b-button',{staticClass:"btn m-2",attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.tooltipForm}},[_vm._v(" บันทึก ")]):_vm._e(),(this.$route.params.poId)?_c('b-button',{staticClass:"btn m-2",attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.updateVehiclePO}},[_vm._v(" บันทึกการแก้ไขข้อมูล ")]):_vm._e()],1)])])])])])])])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }